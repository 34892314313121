/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

.desktopmorgantv {
  width: 100%;
  margin-top: 2rem;
}

.lapisectionhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #00aae4;
}

.getConnectedText {
  color: #00aae4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: justify;
  font-size: 1.2rem;
}

.lapisectionheadbelow {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #00aae4;
}

.ultraclosing {
  width: 100%;
}
.ultradiv {
  background-color: rgba(0, 170, 228, 0.1);
}
.ultratext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #093f89;
  font-size: 1rem;
  text-align: justify;
  /* -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; */
}
.lapidessection li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #093f89;
  text-align: left;
  /* -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; */
}
.qrscan {
  width: 30%;
}

.lapiimag {
  width: 100%;
}

.pavanprofession {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  color: #1f4380;
}

.sunwesttext {
  font-family: "Poppins";
  font-style: normal;
  text-align: justify;
  hyphenate-character: auto;
  font-weight: 400;
  color: #000000;
}

.pavanname {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.9rem;
  color: #ff5b0b;
}

.pavanimg {
  width: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -4rem;
}

.tvsection {
  background: #fff1ea;
}

.scanqr {
  text-align: center;
  margin-top: 2rem;
}
.usetext {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  color: #ff5b0b;
}

.learntext a {
  color: #ff5b0b;
  font-weight: 700;
}
.learntext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: #1f4380;
  font-size: 2rem;
}
.vid-credit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #5b5b5b;
  position: absolute;
  bottom: -0.5rem;
  transform: translateX(calc(50% + 0.75rem));
  pointer-events: none;
}
.custom-headline {
  color: #2d388f !important; 
  padding: 0 10px;
}
.lapisectionheadbelow.custom-headline {
  border-left: 6px solid #2d388f;
}
.pointer {
  cursor: pointer;
}
.ultratext.custom-text {
  color: #2d388f !important;
}
.play-icon {
  height: 4rem;
  width: 4rem;
  position: absolute;
  border-radius: 50%;
  transition: all 0.2s ease-in;
}

.pointer:where(:hover, :active) .play-icon {
  background-color: white;
  border: 1px solid #00aae5;
}
@media screen and (max-width: 991px) {
  .pavanname {
    text-align: center;
  }
  .leftshdoe {
    display: none;
  }

  .dotmatrixright1 {
    display: none;
  }

  .dotmatrixright {
    display: none;
  }
  .lapiimag {
    width: 100%;
    margin-top: -5rem;
  }

  .mobilereverse {
    flex-direction: column-reverse;
  }

  .lapisectionheadbelow {
    margin-top: 3rem;
  }
  .pavanprofession {
    text-align: center;
  }
  /* .lapidessection {
    padding-bottom: 4rem;
  } */
  .lapisectionhead {
    margin-top: 2rem;
  }
  .pavanimg {
    width: 75%;
  }
  .vid-credit {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #5b5b5b;
    position: absolute;
    bottom: -1.5rem;
    transform: translateX(00%);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .getConnectedText {
    font-size: 1rem !important;
  }
  .lapidessection li {
    font-size: 1rem !important;
  }

  .dotmatrixright {
    width: 19px !important;
  }

  .dotmatrixright1 {
    width: 19px !important;
  }
  .vid-credit {
    font-size: 11px;
    transform: translateX(calc(50% + 0.5rem));

  }
}

@media screen and (min-width: 992px) {
  .pavandesktopsection {
    background: #e1f7ff;
    margin-top: 5rem;
    height: 173px;
  }

  .lapisection {
    margin-bottom: 2rem;
  }
  .lapidessection2 {
    padding-top: 2rem;
  }

  .leftshdoe {
    left: 0;
    position: absolute;
    width: 90px;
    z-index: -2;
  }

  .dotmatrixright {
    right: 0;
    position: absolute;
    width: 40px;
    z-index: -2;
    margin-top: 2rem;
  }
  .dotmatrixright1 {
    left: 0;
    position: absolute;
    width: 40px;
    z-index: -2;
    margin-top: 2rem;
  }
  .lapisectionhead {
    padding: 0 10px;
  }
  .lapisectionheadbelow {
    padding: 0 10px;
  }

  .lapisectionheadbelow {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #00aae4;
    border-left: 6px solid #00aae4;
  }
  .lapisectionhead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #00aae4;
    border-left: 6px solid #00aae4;
  }
  .ultraclosing {
    width: 100%;
  }

  .scanqr {
    margin-top: 8rem !important;
  }
  .pavanname {
    margin-top: 3rem;
  }
  .sunwesttext {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .pavanprofession {
    font-size: 1.2rem !important;
  }
}

.lapidessection a {
  color: #1f4380;
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .pavanimg {
    width: 50% !important;
  }
}

@media screen and (min-width: 1400px) {
  .sunwesttext {
    margin-top: 8rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .learntext {
    font-size: 1.5rem;
  }
}
