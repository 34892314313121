.loanOfficerContainer a {
  text-decoration: none;
}

.loanOfficerContainer a:hover {
  text-decoration: underline;
}

.joainfo a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #093f89;
}

.joainfo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  color: #285798;
  line-height: 1.3rem;
}

.jaquienBox {
  max-width: 70%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.jaquienborder {
  max-width: 70%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0.5rem;
  /* border-left: 8px solid #00AAE4;
  border-bottom: 8px solid #00AAE4;
  border-right: 8px solid #00AAE4; */
}

.profileImg {
  border-radius: 10px;
}

.expertisetext {
  text-align: justify;

}

.askAngelAiContainer {
  background-color: #ADD8E6;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  border: 2px solid #00AAE4;
}

.fairlendingNow {
  background: linear-gradient(0deg, #FF434B 20.73%, #FF5422 76.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
}

.aiYouCanBankOn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  color: #07254D;
}

.ask-button a {
  text-decoration: none;
}

.askangelai-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
 
  background: #0B1237;
  border: 1px solid #8E2397;
}

.askangelai-button p {
  margin-bottom: 0px !important;
  position: relative;
  z-index: 1;
  font-size: 1.3rem;
}

.askangelai-button:hover {
  background-color: #FF541C;
  border: none;
}

.askAngelAiNowBtn {
  background-color: #FF541C;
  border-color: #FF541C;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
}

@media screen and (min-width: 992px) {
  .fairlendingNow span{
    font-size: 1.2rem;
  }
  .angelAiInfo {
    padding-top: 5rem;
    font-size: 1rem !important;
  }

  .borderofjoa {
    background: #ffffff;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .askAngelAiContainer {
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .angelai-operator {
    width: 400px;
  }

  .doxmatruxmbie {
    display: none;
  }

  .leftshadowmobile {
    display: none;
  }

  .wavepart {
    position: absolute;
    left: 0;
    z-index: -2;
    width: 90px;
    margin-top: 6rem;
  }

  .rightshdowtst {
    position: absolute;
    right: 0;
    z-index: -2;
    width: 120px;
    margin-top: 6rem;
  }

  .expertisetext {
    font-family: "Poppins";
    font-style: normal;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: 400;
    color: #285798;
    margin-top: 2rem;
  }

  .namejoa {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #04a0d6;
    font-size: 1.7rem;
    margin-left: -2rem;
  }
}

@media screen and (min-width: 1200px) {
  .fairlendingNow {
    font-size: 1.5rem;
  }
  .aiYouCanBankOn {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 991.7px) {
  .fairlendingNow span{
    font-size: 1.2rem;
  }
  .expertisetext {
    font-family: "Poppins";
    font-style: normal;
    text-align: justify;
    hyphens: auto;
    font-weight: 400;
    color: #093f89;
  }

  .angelAiInfo {
    margin-left: 0 !important;
  }

  .joainfo {
    text-align: center;
  }

  .leftshadowmobile {
    position: absolute;
    left: 0;
    z-index: -2;
    width: 140px;
  }

  .doxmatruxmbie {
    position: absolute;
    right: 0;
    z-index: -2;
    width: 40px;
    margin-top: 4rem;
  }

  .rightshdowtst {
    display: none;
  }

  .wavepart {
    display: none;
  }

  .namejoa {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #04a0d6;
    font-size: 1.9rem;
    text-align: center;
    margin-top: 2rem;
  }

  .joainfo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #093f89;
    text-align: center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .jaquienborder {
    max-width: 69% !important;
  }

  .namejoa {
    margin-left: -2.5rem !important;
    font-size: 1.5rem;
  }

  .joainfo {
    font-size: 13.5px;
    line-height: 01.1rem;
    margin-top: -0.5rem;
    margin-left: -40px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .joainfo {
    margin-left: -2rem;
  }
}

@media screen and (min-width: 1400px) and (max-width: 2700px) {
  .namejoa {
    margin-left: -2.5rem !important;
    font-size: 2rem;
  }

  .joainfo {
    font-size: 17.5px;
    line-height: 1.6rem;
    margin-top: -0.5rem;
    margin-left: -40px !important;
  }

  .expertisetext {
    margin-top: 1.3rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media screen and (min-width: 375px) and (max-width: 424.9px) {
  .joainfo {
    font-size: 0.9rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374.9px) {
  .joainfo {
    font-size: 0.75rem !important;
  }
}